import React from "react";
import Layout from "../components/Layout/Layout";

import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Denne siden finnes ikke lenger" />
    <h1>404: Not Found</h1>
  </Layout>
);

export default NotFoundPage;
